.dashboard {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.dashboard__btn {
  padding: 10px;
  font-size: 18px;
  margin-top: 10px;
  border: none;
  color: white;
  background-color: black;
}

.dashboard div {
  margin-top: 7px;
}

.font-bold-card-transactions {
  font: normal normal bold 30px Anek Latin !important;
  color: black !important;
}

.inputForm {
  margin-top: 10px;
  border-radius: 8px;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.inputForm fieldset {
  border: 0px;
}

.inputForm > div > input {
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  outline: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.card-content {
  background: transparent;
  box-shadow: 0px !important;
  color: black;
}

.card-content-header {
  background: white;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: black;
}

.pix-qrcode {
  width: 230px !important;
}

.card-content-body {
  background: white;
  margin-top: 10px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-start !important;
  flex-direction: column;
  padding: 25px !important;
}

p.status-transaction {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-modal-android__close{
  background: #05213C!important;
  border-top: 2px solid #1DFDFF;
  border-right: 2px solid #1DFDFF;
  border-left: 2px solid #1DFDFF;
}

.dialog-modal-android__content{
  /* display: flex;
  justify-content: center;
  align-items: center;
  width: 40vw;
  height: 80vh; */
  background: #05213C!important;
  border: 2px solid #1DFDFF;
}

.dialog-modal-android__progress{
  background-color: rgb(32, 129, 226);
}

.dialog-modal-android__progress__NumericTrait--bar{
  width: 100%;
  background-color: rgb(251, 253, 255);
  border: 1px solid rgb(229, 232, 235);
  border-radius: 14px;
  height: 14px;
  margin-top: 4px;
  overflow: hidden;
}

.dialog-modal-android__progress__NumericTrait--bar-fill{
  background-color: rgb(32, 129, 226);
  border-radius: 14px;
  height: 100%;
}

.dialog-modal-android__skill {
  background-color: rgba(21, 178, 229, 0.06);
  border-radius: 6px;
  border: 1px solid rgb(21, 178, 229);
  padding: 10px;
  text-align: center;
  width: calc(100%/4.2);
}

.dialog-modal-android__skill__type {
  color: rgb(21, 178, 229) !important;
  font-size: 18px !important; 
  font-weight: 500;
  text-transform: uppercase;
}

.dialog-modal-android__skill__value {
  font-size: 15px !important;
  font-weight: 500;
  line-height: 30px;
  overflow: hidden;
  font-weight: normal !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.dialog-modal-android__image{
  width: auto;
  height: 350px;
}

.dialog-modal-android__item_img{
  height: 100%;
}

.rarities-exotic{
  color: #1DFDFF !important;
  font-weight: bold;
}

.rarities-epic{
  color: #8A37EA !important;
  font-weight: bold;
}

.rarities-common{
  color: #ffffff !important;
  font-weight: bold;
}

.rarities-unusual{
  color: #3CF584 !important;
  font-weight: bold;
}

.rarities-rare{
  color: #FFDA10 !important;
  font-weight: bold;
}

.rarities-mythical{
  color: #FF9133 !important;
  font-weight: bold;
}

@media screen and (max-width: 1610px) {
  .modal-android-skills{
    flex-direction: column;
  }
  .modal-android-skills .dialog-modal-android__skill {
    width: 100%;
  }
  .card-content {
    min-width: 95% !important;
  }
  .card-content-header {
    flex-direction: column !important;
  }
  .pix-qrcode {
    width: 90% !important;
  }
  .title-mode-payment {
    font-size: 1.7rem !important;
    margin-bottom: 2px;
  }
  .hour-transaction {
    font-size: 1.2rem !important;
  }
  div.status-transaction{
    display: flex;
    justify-content:space-between;
    align-items: center;
  }
  .status-transaction {
    font-size: 15px !important;
  }
  p.status-transaction {
    font-size: 18px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
