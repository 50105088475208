.MuiBackdrop-root {
  background-color: #05213c47 !important;
}
.boxModalItem {
  width: 200%;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: relative !important;

  padding-bottom: 20px !important;
}
.boxModalItem img {
  width: 546px;
  position: absolute;
  bottom: -50px;
}
.modalContent {
  display: flex;
  height: fit-content;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column !important;
  padding: 0;
}

.closeModal {
  display: flex;
  position: absolute;
  top: 80px;
  right: 120px;
  cursor: pointer;
}
.containerModalContent {
  width: 50%;
  margin-left: 80px;
  margin-top: 8px;
  margin-bottom: 24px;
  height: 100%;
}
.qrCode {
  width: 260px;
  margin-top: 40px;
  border-radius: 5px;
}
.containerModal {

  width: 100%;
  /* height: fit-content; */
  position: relative;
  padding: 0px;
  margin: 0px;
  height:fit-content;

  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.containerModalImage {
  position:relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  height:100%;
}
.containerSpaceShipImage{
  position:absolute;
  top:-310px;
  left:-180px;
  width:546px;
}
.containerAndroidImageGroup{
  /* position:absolute; */
  margin: 16px 0;
  width:300px;
  /* max-height: 200px; */
}
.containerAndroidImage{
  position:absolute;
  top:-120px;
  left:-120px;
  width:300px;
}
@media screen and (max-width: 1920px) {
  .modalItem {
    width: 60vw !important;
  }
}
@media screen and (max-width: 1440px) {
  .modalItem {
    width: 70vw !important;
  }
}
@media screen and (max-width: 1024px) {
  .modalItem {
    width: 90vw !important;
  }
  .boxModalItem img {
    width: 537px;
    left: auto;
    top: -140px;
  }
}
@media screen and (max-width: 768px) {

.containerSpaceShipImage{
  position:relative;
  top:0px;
  left:0px;
  width:300px;
}
.containerAndroidImageGroup{
  position:relative;
  top:0px;
  left:0px;
  width:300px;
}
.containerAndroidImage{
  position:relative;
  top:0px;
  left:0px;
  width:300px;
}
  .closeModal {
    top: 15px;
    right: -20px;
  }
  .modalItem {
    width: 90vw !important;
  }
  .containerModal {
    flex-direction: column !important;
    justify-content: flex-start !important;
  }
  .boxModalItem {
    width: 100% !important;
    height: 270px !important;
  }
  .boxModalItem img {
    width: 537px;
    left: auto;
    top: -150px;
  }
  .modalContent{

    padding-bottom: 20px !important;
  }
  .containerModalContent {
    margin-left: 50px;
    margin-top: 0px;
    height: 60%;
    width: 90% !important;
  }
}
@media screen and (max-width: 425px) {
  .boxModalItem {
    margin-top: -50px;
  }
  .boxModalItem img {
    width: 374px;
    left: auto;
    top: -35px;
  }
  .containerModalContent {
    margin-left: 0px;
  }
}
@media screen and (max-width: 375px) {
  .boxModalItem img {
    width: 374px;
    left: auto;
    top: -35px;
  }
}

@media screen and (max-width: 320px) {
  .boxModalItem img {
    width: 320px;
    left: auto;
    top: -35px;
  }
}
