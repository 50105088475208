.dashboard {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.dashboard__btn {
  padding: 10px;
  font-size: 18px;
  margin-top: 10px;
  border: none;
  color: white;
  background-color: black;
}

.dashboard div {
  margin-top: 7px;
}



.font-bold-card-transactions{
  font: normal normal bold 30px Anek Latin !important;
  color: black !important;
}

.inputForm{
  margin-top: 10px;
  border-radius: 8px;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.inputForm fieldset {
  border: 0px;
}

.inputForm>div>input {
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  outline: none;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.card-content{
  background: transparent;
  box-shadow: 0px !important;
  color: black;
}

.card-content-header{
  background: white;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: black;
}

.pix-qrcode{
  width: 110px;
}

.card-content-body{
  background: white;
  margin-top: 10px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-start !important;
  flex-direction: column;
  padding: 25px !important;
}