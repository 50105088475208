.dashboard {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.dashboard__btn {
  padding: 10px;
  font-size: 18px;
  margin-top: 10px;
  border: none;
  color: white;
  background-color: black;
}

.dashboard div {
  margin-top: 7px;
}

.font-bold {
  font: normal normal bold 20px Anek Latin !important;
  color: #ffffff !important;
}

.inputForm {
  margin-top: 10px;
  border-radius: 8px;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.inputForm fieldset {
  border: 0px;
}

.inputForm > div > input {
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  outline: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
.btn-connect-wallet {
  height: 57px !important;
  margin-top: 1px;
}
.btn-save-account {
  height: 57px !important;
}
.btn-save-account:hover,
.btn-connect-wallet:hover {
  background: #2cfff61d 0% 0% no-repeat padding-box !important;
}
.btn-save-account::before,
.btn-connect-wallet::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 20px;
  top: -5px;
  background-color: #0c3658;
  border-left: 2px solid #3cf584;
  right: 3px;
  transform: rotate(-46deg);
}
.btn-save-account::after,
.btn-connect-wallet::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 20px;
  bottom: -5px;
  background-color: #0c3658;
  border-right: 2px solid #3cf584;
  left: 3px;
  transform: rotate(-46deg);
}
