.loginTitle {
  font-size: 23px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  background: transparent;
  border: none;
  width: 15vw;
  display: flex;
  justify-content: flex-start;
  font: normal normal normal 23px/14px Anek Latin;
}

button[aria-selected="true"] {
  font: normal normal bold 23px/14px Anek Latin !important;
}
.btn-login-default,
.btn-register {
  height: 57px !important;
}
.btn-login-default:hover,
.btn-register:hover {
  background: #2cfff61d 0% 0% no-repeat padding-box !important;
}

.login__btn {
  font-family: Anek Latin !important;
  font-style: normal !important;
  color: #747474 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border-radius: 50px !important;
  height: 57px !important;
  justify-content: flex-start !important;
}
.login__btn:hover {
  color: #fff !important;
}
.login__btn .MuiButton-startIcon {
  margin-left: 0 !important;
  margin-right: 15px !important;
}
.inputForm {
  margin-top: 10px;
  border-radius: 8px;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.inputForm > div > input {
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  outline: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.inputForm fieldset {
  border: 0px;
}

.login__google{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}