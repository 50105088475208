.buttonDesktop{
  display: flex;
  width: 10%;
  alignItems: center;
  justifyContent: flex-end;
}
.buttonMobile{
  display:none;
}
@media screen and (max-width: 899px){
  .buttonMobile{
    display:flex;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .buttonDesktop{
    display: none;
  }
}