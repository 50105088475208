@font-face {
  font-family: "Anek Latin";
  src: url("../fonts/AnekLatin-Medium.eot");
  src: local("Anek Latin Medium"), local("AnekLatin-Medium"),
    url("../fonts/AnekLatin-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/AnekLatin-Medium.woff2") format("woff2"),
    url("../fonts/AnekLatin-Medium.woff") format("woff"),
    url("../fonts/AnekLatin-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

a {
  color: white;
}

body {
  margin: 0;
  font: normal normal normal 23px/14px Anek Latin !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiModal-root {
  z-index: 999 !important;
}

.whatsapp-icon {
  position: fixed;
  bottom: 10px;
  right: 10px;
  height: 65px !important;
  width: auto !important;
}

.ninjas-background-div {
  background: url("../../../public/images/androids-crop.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
  width: 100%;
  padding-left: 0px !important;
  border: 0;
  margin: 0px;
  padding: 0px;
  opacity: 100%;
}

.tab-panel-login {
  width: 100% !important;
}

.tab-panel-login-btn {
  width: 50% !important;
}

.MuiTabs-indicator {
  background-color: #1dfdff !important;
}

/* BTN UNIVERSUS WITH BORDER */

/* END BTN UNIVERSUS WITH BORDER */

.dialog-modal-image {
  display: none;
}

@media screen and (max-width: 900px) {
  .dialog-modal-image {
    display: block;
  }

  header {
    position: fixed !important;
    top: 0px;
  }

  header > div {
    background-color: #0000009c !important;
  }

  .status-wallet {
    margin-top: 120px;
  }

  .closeModal img {
    /* margin-left: 10px !important; */
  }

  .spaceships-boxes {
    margin: 40px 0px 0px 25px !important;
  }

  .spaceships-boxes p {
    max-width: 90% !important;
  }

  #simple-tabpanel-0 button,
  #simple-tabpanel-1 button {
    font-size: 16px !important;
  }

  .whatsapp-icon {
    position: fixed;
    bottom: 5px;
    right: 10px;
    height: 60px !important;
    width: auto !important;
  }

  .ninjas-background-div {
    display: none;
  }

  .container-login {
    background: url("../../../public/images/androids-opacity.png") no-repeat center
      center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .banner-desktop {
    display: none;
  }

  .banner-mobile {
    display: block !important;
  }

  .btn-connect-wallet {
    display: none !important;
  }

  .warning-wallet-mobile {
    display: block !important;
  }
}
