.btn-universus {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
  padding-right: 47px ;
  padding-left: 47px ;
  font: normal normal bold 18px/14px Anek Latin !important;
  color: #ffffff !important;
  min-height: 57px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  min-width: 100px !important;
  box-shadow: none !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-universus.disabled {
  opacity: 0.7 !important;
  cursor: not-allowed !important;
}

/* BTN UNIVERSUS WITH BORDER */

/* Universus button blue */

.btn-universus-blue {
  background-image: url(./background/blue.svg);
  background-size: 100% 100%;
  background-color: transparent !important;
  background-repeat: no-repeat;

  /* box-shadow: inset 10px 10px #072744; */
}
.btn-universus-blue-large {
  background-image: url(./background/blue-large.svg);
  background-size: 100% 100%;
  background-color: transparent !important;
  background-repeat: no-repeat;
}

.btn-universus-green {
  background-image: url(./background/green.svg);
  background-size: 100% 100%;
  background-color: transparent !important;
  background-repeat: no-repeat;
  /* padding: 15px !important; */
}

.btn-universus-green-large {
  background-image: url(./background/green-large.svg);
  background-size: 100% 100%;
  background-color: transparent !important;
  background-repeat: no-repeat;
}



.btn-universus-red {
  background-image: url(./background/red.svg);
  background-size: 100%;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  padding-right: 35px !important;
  padding-left: 35px !important;
  background-color: transparent !important;
  background-repeat: no-repeat;
}

.btn-universus-yellow {
  background-image: url(./background/yellow.svg);
  background-size: 100%;
  background-color: transparent !important;
  background-repeat: no-repeat;
}

/* END BTN UNIVERSUS WITH BORDER */
